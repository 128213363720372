.infinite-pagination-container {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 6px;
}

.infinite-pagination-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    border: 0;
    border-radius: 3px;
    margin: 0;
    width: 32px;
    height: 32px;
    transition: all 0.2s ease;
}

.infinite-pagination-button:hover {
    filter: brightness(0.95);
}

.active {
    filter: brightness(0.95);
}