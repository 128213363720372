/* Provide sufficient contrast against white background */
@import url('https://fonts.googleapis.com/css?family=Kanit');

@tailwind base;
@tailwind components;
@tailwind utilities;

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: inline-block;
    vertical-align: middle;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
}

.btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
}

body {
    font-family: sans-serif;
    font-size: 14px;
}

@media (min-width: 768px) {
    .container {
        max-width: 100%;
    }
}

@media (min-width: 576px) {
    .container {
        max-width: 100%;
    }
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.Loader {
    height: 100vh;
}

.Loader__background {
    display: none !important;
    z-index: 2000 !important;
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.Loader__background.hide {
    display: none !important;
}

.Loader__background.show {
    display: block !important;
}


.trapezoid {
    border-bottom: 32px solid #3397c3;
    border-right: 25px solid transparent;
    height: 0;
    width: 200px;
    color: white;
    margin-top: 8px;
    z-index: 10
}

.trapezoid.active {
    height: 30px;
    box-shadow: 0 5px 5px -5px #333;
}

.trapezoid-purple {
    border-bottom: 32px solid #a665af;
}

.trapezoid-blue {
    border-bottom: 32px solid #3d5a80;
}

.trapezoid>span {
    display: inline-block;
    color: white;
    font-size: 14px;
    /* font-weight: bold; */
    padding: 8px 0px 8px 8px;
}

.trapezoid-head {
    border-bottom: 32px solid #3397c3;
    border-right: 25px solid transparent;
    height: 0;
    width: 200px;
    color: white;
    margin-top: 8px;
    text-align: center;
}

.trapezoid-head>span {
    display: inline-block;
    color: white;
    font-size: 14px;
    padding: 8px 8px 8px 8px;
}

.mat-box {
    border-radius: 0px 5px 5px 5px;
    border: 1px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    background: #fff;
    padding: 15px;
    margin-bottom: 10px;
}

.master-data-header {
    background: #fff;
    height: 57px;
    overflow: hidden;
    padding: 7px 20px;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.master-data-content {
    /*padding: 15px 20px;
    background: #f7f7f7;*/
}

.nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #a665af;
    border-color: #dee2e6 #dee2e6 #fff;
}

.pointer {
    cursor: pointer;
}

.horizon-tab {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
}

/*.scrollbar {
    margin-left: 30px;
    float: left;
    height: 300px;
    width: 65px;
    background: #F5F5F5;
    overflow-y: scroll;
    margin-bottom: 25px;
}
*/
.custom-scrollbar::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F5F5F5;
}

.custom-scrollbar::-webkit-scrollbar {
    height: 8px;
    width: 1px;
    background-color: #F5F5F5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #adb5bd;
}

.require-field {
    background: #fcffde;
    border-left: 3px solid #dea80d;
    background-color: white !important;
}

.blue-require-style {
    border-left: 3px solid #9FA8DA;
    background-color: white !important;
}

.green-require-style {
    border-left: 3px solid #90be6d;
    background-color: white !important;
}

.react-select__value-container {
    z-index: 2000
}

.react-datepicker-popper {
    z-index: 9999 !important;
}

.custom-table-align-left td {
    text-align: left
}

.custom-table-align-center td {
    text-align: center
}

.custom-table-align-right td {
    text-align: right
}

.custom-checkbox input[type=checkbox]:focus {
    outline: 1px solid rgba(0, 0, 0, 0.2);
}

.responsive-table {
    overflow: auto;
}

.nowrap {
    white-space: nowrap !important;
}

.custom-checkbox input[type=checkbox] {
    background-color: #DDD;
    border-radius: 2px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 17px;
    height: 17px;
    cursor: pointer;
    position: relative;
    top: 5px;
}

.custom-checkbox input[type=checkbox]:checked {
    background-color: #fff;
    background: #fff url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAbQAAAG0BjlwfygAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACGSURBVDiNxdAxCgIxEAXQp/1eQARlT2DnObyEl9Bij2ApeDfRfgsLW1mbDawDQhILB6ZJ/vuB8KdpsK7FLW54YV+D7xjGffyCB5xy8QrXgC+Yp8AWi4KXz5ilwHE87LEpxfCcXE5LsjAcQqjHLhen6UI47seHlZZk4W8lRTiWVOE0y1pYPG/VW0IYJ55ztwAAAABJRU5ErkJggg==') 0.5px 0.5px no-repeat;
}

.disable-div {
    cursor: not-allowed;
    pointer-events: none;
}

.import-export-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (min-width: 992px) {}

.create-criteria {
    background-color: #3B73A7;;
    border-radius: 10px;
    color: white;
    text-align: center;
}