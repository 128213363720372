a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar {
    padding: 0 !important;
}

.navbar-inverse .navbar-brand {
    color: #9d9d9d;
    padding: 0;
}

.navbar-brand {
    padding: 0 !important;
}

.navbar-brand {
    float: left;
    height: 50px;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 20px;
}

.trapezoid-logo {
    color: #0D0D0D;
    display: inline-block;
    border-bottom: 50px solid #EEEEEE;
    border-right: 25px solid transparent;
    height: 0;
    width: 251px;
    color: white;
    text-align: center;
}

.trapezoid-logo-span {
    border-bottom: 49px solid #EEEEEE;
    border-right: 25px solid transparent;
    height: 0;
    width: 251px;
    color: white;
    text-align: center;
}

.nav-span-brand {
    color: #fff;
    margin-left: 15px;
    display: inline-block;
    margin-top: 15px;
}

/* .nav-brand-name {
    display: inline;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
} */

.dropdown-menu {
    position: absolute;
    right: 0;
    left: unset;
}

@media (max-width: 992px) {
    .trapezoid-logo {
        display: none;
    }
}

.bg-navbar {
    background-image: url('../../assets/image/bglogin.png');
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}