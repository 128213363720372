/* .hold th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;
    background: #fff;
}

table {
    background-color: #aaa;
}
tbody {
    background-color: #ddd;
    height: 720px;
    overflow-y: auto;
}

thead > tr, tbody{
    display:block;
} */

.custom-table {
    border-collapse: collapse;
    width: 100%;
    border: solid 1px #c0c0c0;
    font-family: sans-serif;
    font-size: 12px;
}
    .custom-table th {
        padding: 8px;
        border: solid 1px #E7E7E7;
        font-weight: 200;
        font-size: 14px;
        vertical-align: middle;
    }

    .custom-table td {
        padding: 0px 4px 0px 4px;
        border: solid 1px #E7E7E7;
        font-weight: 200;
        font-size: 14px;
        vertical-align: middle;
    }
    .custom-table > thead > tr {
        word-break: normal;
        background: #FFFFFF !important;
        color: #333 !important;
        font-size: 14px;
    }
        .custom-table > thead > tr > th {
            vertical-align: middle !important;
            background-color: #ffffff !important;
            font-weight:600!important;
            text-align:left!important;
        }
        .custom-table > thead > tr > th:not(.th-center) {
            text-align:left!important;
        }
        .custom-table > thead > tr > .th-center {
            text-align:center!important;
        }
    .custom-table tr:nth-child(odd) {
        background-color: #f7f7ff;
    }